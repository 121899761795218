import { GoCheckCircle } from 'react-icons/go';
import { Button } from '@components/button';

export function ModalInviteNewUserSuccessMessage({ onClose }) {
  return (
    <div className="w-[100%] py-7">
      <div className="flex flex-col items-center justify-center gap-10">
        <div className="flex flex-col gap-4">
          <div className="text-center">
            <GoCheckCircle size={40} className="text-price" />
          </div>
          <p className="m-0 text-center text-2xl font-bold text-price">
            Convite(s) enviado(s) com sucesso!
          </p>
          <p className="m-0 max-w-[580px] text-center">
            O convite para acesso à plataforma foi enviado com sucesso!
            Acompanhe o status dos convites na lista de usuários.
          </p>
        </div>
        <div className="flex h-[48px] justify-center gap-4">
          <Button
            className="px-4"
            onClick={onClose}
            variant="ghost"
            label="Fechar"
            type="button"
          />
        </div>
      </div>
    </div>
  );
}
