import { CiWarning } from 'react-icons/ci';
import { PiWarningCircle } from 'react-icons/pi';

import { Button } from '@components/button';

export function ModalInviteNewUserErrorMessage({
  onClose,
  onTryAgain,
  onRetryAgain,
  isPartial = false,
}) {
  return (
    <div className="w-[100%] py-7">
      <div className="flex flex-col items-center justify-center gap-10">
        <div className="flex flex-col gap-4">
          <div className="text-center">
            {isPartial ? (
              <CiWarning size={40} className="text-warning-500" />
            ) : (
              <PiWarningCircle size={40} className="text-error-500" />
            )}
          </div>
          {isPartial ? (
            <p className="m-0 text-center text-2xl font-bold text-warning-500">
              Envio parcialmente concluído!
            </p>
          ) : (
            <p className="m-0 text-center text-2xl font-bold text-error-500">
              Falha no envio dos convites!
            </p>
          )}
          <p className="m-0 max-w-[580px] text-center">
            {isPartial
              ? `Alguns convites foram enviados com sucesso, mas outros não. Acompanhe o status dos convites na lista de usuários.`
              : `Algo deu errado e os convites não foram enviados. or favor, revise
            os dados e tente novamente.`}
          </p>
        </div>
        <div className="flex h-[48px] justify-center gap-4">
          <Button
            className="px-4"
            onClick={onClose}
            variant="ghost"
            label="Fechar"
            type="button"
          />
          {isPartial ? (
            <Button
              className="px-4"
              onClick={onRetryAgain}
              label="Tentar novamente"
              type="button"
            />
          ) : (
            <Button
              className="px-4"
              onClick={onTryAgain}
              label="Reenviar convites"
              type="button"
            />
          )}
        </div>
      </div>
    </div>
  );
}
