import { create } from 'zustand';
import Globals from '@components/Globals';
import * as Sentry from '@sentry/react';
import api from '../services/api';

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refresh_token');

  if (!refreshToken) throw new Error('Refresh token not found!');

  const { data } = await api.post('/authentication/refresh-token', {
    refresh_token: refreshToken,
  });

  localStorage.setItem('access_token', data.access_token);
  return data.access_token;
};

export const useAuthentication = create((set) => ({
  //States
  session: null,
  loading: false,
  token: null,
  methods: {
    signIn: async ({ email, password }, method = 'email', code = '') => {
      set({ loading: true });
      try {
        let data;

        if (method === 'email') {
          const response = await api.post('/authentication/sign-in', {
            email,
            password,
          });

          data = response.data;
        } else {
          const response = await api.post(`/authentication/google`, {
            code,
          });

          data = response.data;
        }

        if (!data?.access_token || !data?.user) return false;

        set({ session: data.user, token: data.access_token });

        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
        api.defaults.headers.Authorization = `Bearer ${data.access_token}`;

        Sentry.setUser({
          id: data.user?.id,
          email: data.user?.email,
          agency_id: data.user?.agency?.id,
        });

        //Ajuste temporário - Inicio
        Globals.userId = data.user?.id;
        Globals.userName = data.user?.firstName;
        Globals.userEmail = data.user?.email;
        Globals.userStatus = data.user?.status;
        Globals.userIdAgencia = data.user?.agency?.id;
        Globals.userNameAgencia = data.user?.agency?.tradeName;
        Globals.userAllotmentAgencia = data.user?.agency?.allotment;
        Globals.userAllotmentVencimentoAgencia = data.user?.agency?.dueDate;
        Globals.userAllowedPaymentMethods = data.user?.agency?.payment_methods;
        Globals.userCreditoAgencia = data.user.agency?.credit;
        Globals.userCreditoUsadoAgencia = '0';
        Globals.userAllotmentUsadoAgencia = '0';

        localStorage.setItem('user_id', data.user?.id);

        if (data.user?.imageUrl) {
          Globals.userImage = data.user.imageUrl;
        } else {
          if (data.user?.image != null) {
            Globals.userImage = data.user.image;
          }
        }

        set({ loading: false });
        //Ajuste Temporário - Fim
        return true;
      } catch (err) {
        set({ loading: false });
        throw err?.message;
      }
    },
    signOut: async () => {
      set((prevState) => ({ ...prevState, session: null, token: null }));
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    },
    refreshToken: async () => {
      set({ loading: true });
      try {
        const ret = await refreshToken();
        set({ loading: false });
        return ret;
      } catch (error) {
        set({ loading: false });
        console.error('Erro ao renovar o access token', error);
        throw error;
      }
    },
    validateToken: async () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) return false;

        const { data } = await api.get('/authentication/validate');
        if (!data.user) return false;

        set({ session: data.user, token });

        Sentry.setUser({
          id: data.user?.id,
          email: data.user?.email,
          agency_id: data.user?.agency?.id,
        });

        //Ajuste temporário - Inicio
        Globals.userId = data.user?.id;
        Globals.userName = data.user?.firstName;
        Globals.userEmail = data.user?.email;
        Globals.userStatus = data.user?.role;
        Globals.userIdAgencia = data.user.agency?.id;
        Globals.userNameAgencia = data.user.agency?.nomeFantasia;
        Globals.userAllotmentAgencia = data.user.agency?.allotment;
        Globals.userAllotmentVencimentoAgencia = data.user.agency?.vencimento;
        Globals.userCreditoAgencia = data.user.agency?.credito;
        Globals.userCreditoUsadoAgencia = '0';
        Globals.userAllotmentUsadoAgencia = '0';

        if (data.user.imageUrl) {
          Globals.userImage = data.user.imageUrl;
        } else {
          if (data.user.image != null) {
            Globals.userImage = data.user.image;
          }
        }
        set({ loading: false });
        //Ajuste Temporário - Fim
        return true;
      } catch (err) {
        set({ session: null, token: null });

        return false;
      }
    },
  },
}));
