import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { tv } from 'tailwind-variants';
import * as yup from 'yup';

import { PagePath } from '@/pages-config';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import { useAuthentication } from '@stores/authentication';

import './style.css';
import GoogleLogo from '@assets/svg/GoogleLogo';

const cognitoOauthUrl = import.meta.env.VITE_COGNITO_OAUTH_URL;
const cognitoClientId = import.meta.env.VITE_COGNITO_CLIENT_ID;
const cognitoOauthRedirectUrl = import.meta.env.VITE_COGNITO_OAUTH_REDIRECT_URL;

const styles = {
  button: tv({
    base: 'font-semibold',
    variants: {
      loading: {
        true: 'hidden',
      },
    },
  }),
  loader: tv({
    base: [
      'relative top-[-20px] h-4 w-4 rounded-full text-[8px] text-white',
      'before:absolute before:left-[-3.5em] before:top-0 before:h-4 before:w-4 before:rounded-full before:content-[""]',
      'after:absolute after:left-[3.5em] after:top-0 after:h-4 after:w-4 after:rounded-full after:content-[""]',
    ],
    variants: {
      loading: {
        false: 'hidden',
        true: 'vgp-spinner',
      },
    },
  }),
};

const schemaValidation = yup.object().shape({
  email: yup
    .string()
    .label('E-mail')
    .required('E-mail é obrigatório')
    .email('Digite um e-mail válido'),
  password: yup.string().label('Senha').required('Digite uma senha'),
});

export default function Login() {
  const { loading, methods } = useAuthentication();

  const location = useLocation();
  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaValidation),
    mode: 'all',
  });

  const handleMouseHover = (value) => setIsHovered(value);

  const googleLogin = () => {
    const googleLoginUrl = `${cognitoOauthUrl}?response_type=code&client_id=${cognitoClientId}&redirect_uri=${cognitoOauthRedirectUrl}&scope=email`;

    window.location.href = googleLoginUrl;
  };

  async function onSubmit(formData) {
    try {
      const userHasAuthenticated = await methods.signIn(formData);
      if (!userHasAuthenticated) return;

      if (location.state?.from) {
        window.location = location.state?.from;
      } else {
        navigate(PagePath.Home);
      }
    } catch (err) {
      toast.error(err);
    }
  }

  return (
    <>
      <Form className="mt-[60px] text-center" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Form.Label>Login</Form.Label>
          <InputV2
            type="email"
            placeholder="Digite seu e-mail"
            {...register('email')}
          />
          <Form.Message error={errors?.email} />
        </Form.Group>
        <Form.Group className="mt-4">
          <Form.Label>Senha</Form.Label>
          <InputV2
            type="password"
            placeholder="Digite sua senha"
            {...register('password')}
          />
          <Form.Message error={errors?.password} />
        </Form.Group>
        <Link
          className="mt-[10px] inline-block text-sm font-semibold"
          to={PagePath.RecuperarSenha}
          relative="path"
        >
          Esqueceu a senha?
        </Link>
        <button type="submit" className="mt-[22px] h-[46px]">
          <span className={styles.button({ loading })}>Entrar</span>
          <span className={styles.loader({ loading })}></span>
        </button>
        <button
          type="button"
          className="mt-4 flex h-[46px] justify-center border border-[#eb0045] bg-white font-semibold text-[#eb0045] hover:border-0 hover:text-white"
          onClick={() => googleLogin()}
          onMouseEnter={() => handleMouseHover(true)}
          onMouseLeave={() => handleMouseHover(false)}
        >
          <GoogleLogo height={16} width={16} allWhite={isHovered} />

          <span className="ml-2">Entrar com Google</span>
        </button>
        <Link
          className="mt-[18px] inline-block text-sm font-semibold"
          to={PagePath.NovoUsario}
          relative="path"
        >
          Quer saber mais sobre a plataforma?
        </Link>
      </Form>
    </>
  );
}
