import * as React from 'react';

function GoogleLogo({ height = 20, width = 20, allWhite = false }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.17 8.368h-.67v-.035H10v3.333h4.709A4.998 4.998 0 014.999 10a5 5 0 015-5c1.275 0 2.434.48 3.317 1.266l2.357-2.357A8.295 8.295 0 0010 1.667a8.334 8.334 0 108.172 6.7z"
        fill={allWhite ? '#FFF' : '#FFC107'}
      />
      <path
        d="M2.627 6.121L5.365 8.13a4.998 4.998 0 014.634-3.13c1.275 0 2.435.482 3.317 1.267l2.358-2.357a8.295 8.295 0 00-5.675-2.242A8.329 8.329 0 002.627 6.12z"
        fill={allWhite ? '#FFF' : '#FF3D00'}
      />
      <path
        d="M10 18.333a8.294 8.294 0 005.588-2.163l-2.58-2.183A4.963 4.963 0 0110.002 15 4.998 4.998 0 015.3 11.689l-2.718 2.094a8.327 8.327 0 007.419 4.55z"
        fill={allWhite ? '#FFF' : '#4CAF50'}
      />
      <path
        d="M18.171 8.368H17.5v-.034H10v3.333h4.71a5.017 5.017 0 01-1.703 2.321l2.58 2.182c-.182.166 2.746-2.003 2.746-6.17 0-.559-.057-1.104-.162-1.632z"
        fill={allWhite ? '#FFF' : '#1976D2'}
      />
    </svg>
  );
}

export default GoogleLogo;
