import { useEffect, useMemo, useState } from 'react';
import { GoAlertFill } from 'react-icons/go';
import { IoAirplaneOutline } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button } from '@components/button';
import { DropdownListRoutes } from '@components/dropdown-list-routes';
import { InputText, InputDate, InputRadio } from '@components/input';
import IntlCurrencyInput from '@components/intl-currency-input';
import { Modal } from '@components/modal';
import { Form } from '@components/new-components/form';
import PageHeader from '@components/page-header';
import { RouteCard } from '@components/route-card';
import { Select } from '@components/select';
import { Switch } from '@components/switch';
import Tag from '@components/Tag';
import api from '@services/api';
import { useAirports } from '@stores/airports';
import { inputFormatterPercentage } from '@system/utils/inputFormatter';
import { toaster } from '@system/utils/toaster';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';

export default function AereoSaidasEditar() {
  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const { methods } = useAirports();
  const navigate = useNavigate();
  const { id } = useParams();

  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [dataIda, setDataIda] = useState('');
  const [dataVolta, setDataVolta] = useState('');
  const [contratante, setContratante] = useState('');
  const [dataBaseRotasOrigem, setDataBaseRotasOrigem] = useState([]);
  const [dataFilterBaseRotasOrigem, setDataFilterBaseRotasOrigem] = useState(
    [],
  );
  const [ida, setIda] = useState('');
  const [volta, setVolta] = useState('');
  const [primeiraclasseNorma, setPrimeiraclasseNorma] = useState('');
  const [premiumNorma, setPremiumNorma] = useState('');
  const [executivaNorma, setExecutivaNorma] = useState('');

  const [quantidadeBagagem, setQuantidadeBagagem] = useState(0);
  const [pesoBagagem, setPesoBagagem] = useState('');

  const [departureOrigin, setDepartureOrigin] = useState(null);
  const [returnOrigin, setReturnOrigin] = useState(null);
  const [active, setActive] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const [canChange, setCanChange] = useState('');
  const [withCost, setWithCost] = useState('');
  const [changeCost, setChangeCost] = useState();
  const [limitDay, setLimitDay] = useState();

  const [canCancel, setCanCancel] = useState('');
  const [withRefund, setWithRefund] = useState('');
  const [withAditionalCost, setWithAditionalCost] = useState('');
  const [withDueDate, setWithDueDate] = useState('');
  const [cancelRefundPercentageAfter, setCancelRefundPercentageAfter] =
    useState();
  const [cancelLimitDay, setCancelLimitDay] = useState();
  const [cancelCostPercentage, setCancelCostPercentage] = useState();
  const [cancelRefundPercentage, setCancelRefundPercentage] = useState();

  const selectedDepartureDate = dayjs(dataIda).add('1', 'day');
  const selectedReturnDate = dayjs(dataVolta);

  const [isOnlyOneExcerpt, setIsOnlyOneExcerpt] = useState(false);

  useEffect(() => {
    setLoading(true);

    api
      .get(`charters/${id}/get/by/custom/query`)
      .then((response) => {
        if (response.data.length > 0) {
          setDataIda(response.data[0]['data_frete']);
          setDataVolta(response.data[0]['date_volta']);
          setPrimeiraclasseNorma(response.data[0]['primeiraclasse_frete']);
          setPremiumNorma(response.data[0]['premium_frete']);
          setExecutivaNorma(response.data[0]['executiva_frete']);
          setDataIda(response.data[0]['data_frete']);
          setIda(response.data[0]['rota_ida']);
          setVolta(response.data[0]['rota_volta']);
          setActive(Boolean(response.data[0]['ativo'] === 1));
          setContratante(response.data[0]['contratante']);
          const bagagem = response.data[0]['bagagem']?.split('-');
          setQuantidadeBagagem(bagagem[0]);
          setPesoBagagem(bagagem[1] ?? '');
          let rota_volta = response.data[0]['rota_volta'] ?? null;
          setIsOnlyOneExcerpt(rota_volta ? true : false);
        }
      })
      .catch((error) => console.log(error));

    api
      .get('/charters/get/informations/routes/items')
      .then((response) => {
        if (response.data.length > 0) {
          setDataBaseRotasOrigem(response.data);
          setDataFilterBaseRotasOrigem(response.data);
        }
      })
      .catch((error) => alert(error))
      .finally(() => setLoading(false));

    methods.getAirports();
    getRules();
  }, []);

  const getRules = async () => {
    const charter = (await api.get(`charters/${id}`)).data;

    const canChangeTemp =
      charter?.changeRule &&
      (charter?.changeRule?.changeWithCost ||
        charter?.changeRule?.changeCostValue ||
        charter?.changeRule?.changeLimitDays);
    setCanChange(canChangeTemp ? 'Y' : 'N');
    setWithCost(charter?.changeRule?.changeWithCost ? 'Y' : 'N');
    if (canChangeTemp) {
      setChangeCost(charter?.changeRule?.changeCostValue);
      setLimitDay(charter?.changeRule?.changeLimitDays);
    }

    const cancelRule = charter?.cancelRule;
    const canCancelTemp =
      cancelRule &&
      (cancelRule?.cancelWithRefund ||
        cancelRule?.refundWithDeadline ||
        cancelRule?.cancelWithCost ||
        cancelRule?.refundPercentageDeadline ||
        cancelRule?.cancelCostPercentage ||
        cancelRule?.refundPercentage);

    setWithRefund(cancelRule?.cancelWithRefund ? 'Y' : 'N');
    setWithDueDate(cancelRule?.refundWithDeadline ? 'Y' : 'N');
    setWithAditionalCost(
      cancelRule?.cancelWithCost && cancelRule?.cancelCostPercentage
        ? 'Y'
        : 'N',
    );

    const canCancelRule =
      cancelRule &&
      !(
        cancelRule.cancelWithCost === undefined &&
        cancelRule.cancelCostPercentage === undefined
      ) &&
      (!cancelRule.cancelWithCost ||
        !cancelRule.cancelCostPercentage ||
        (cancelRule.cancelWithCost && cancelRule.cancelCostPercentage));

    setCanCancel(canCancelRule ? 'Y' : 'N');

    if (canCancelTemp) {
      setCancelLimitDay(cancelRule?.refundDaysLimit);

      const refundPercentageDeadline = Number(
        cancelRule?.refundPercentageDeadline,
      );
      setCancelRefundPercentageAfter(
        inputFormatterPercentage({
          value: refundPercentageDeadline,
        }),
      );
      const cancelCostPercentage = Number(cancelRule?.cancelCostPercentage);
      setCancelCostPercentage(
        inputFormatterPercentage({
          value: cancelCostPercentage,
        }),
      );
      const refundPercentage = Number(cancelRule?.refundPercentage);
      setCancelRefundPercentage(
        inputFormatterPercentage({
          value: refundPercentage,
        }),
      );
    }
  };

  const handleCancelLimitDay = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setCancelLimitDay(value);
  };

  const handleChangeCost = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setChangeCost(value / 100);
  };

  const handleLimitDay = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setLimitDay(value);
    return value;
  };

  const parsePercentage = (value) => Number(value?.replace('%', ''));

  const buildCancelRulesData = ({
    hasRefund,
    hasDueDate,
    withAditionalCost,
    cancelCostPercentage,
    cancelRefundPercentage,
    cancelLimitDay,
    cancelRefundPercentageAfter,
  }) => {
    const cancelRulesData = {
      cancelWithCost:
        withAditionalCost === 'Y' && !hasRefund && cancelCostPercentage,
      cancelWithRefund: hasRefund,
      refundWithDeadline: hasRefund && hasDueDate,
    };

    if (!hasRefund && cancelCostPercentage) {
      cancelRulesData.cancelCostPercentage =
        parsePercentage(cancelCostPercentage);
    }

    if (hasRefund && cancelRefundPercentage) {
      cancelRulesData.refundPercentage = parsePercentage(
        cancelRefundPercentage,
      );
    }

    if (hasRefund && hasDueDate && cancelLimitDay) {
      cancelRulesData.refundDaysLimit = cancelLimitDay;
    }

    if (hasRefund && hasDueDate && cancelRefundPercentageAfter) {
      cancelRulesData.refundPercentageDeadline = parsePercentage(
        cancelRefundPercentageAfter,
      );
    }

    return cancelRulesData;
  };

  function validaForm(e) {
    e.preventDefault();
    setLoading(true);

    if (selectedDepartureDate <= today) {
      setLoading(false);
      return toaster(
        'warning',
        'Por favor, selecione uma data de ida posterior ou igual a hoje.',
      );
    } else if (
      new Date(selectedReturnDate) < selectedDepartureDate &&
      isOnlyOneExcerpt
    ) {
      setLoading(false);
      return toaster(
        'warning',
        'A data de volta não pode ser menor que a data de ida.',
      );
    }

    let errors = {};
    if (canChange == '' || (canChange == 'Y' && withCost == '')) {
      errors.change = {
        message: 'Campo obrigatório',
      };
    } else {
      if (canChange == 'Y') {
        if (
          (withCost == 'Y' && !changeCost) ||
          (withCost == 'N' && (!changeCost || !limitDay))
        ) {
          errors.change = {
            message: 'Campo obrigatório',
          };
        }
      }
    }

    if (canCancel == '') {
      errors.cancel = {
        message: 'Campo obrigatório',
      };
    } else if (canCancel == 'Y' && withRefund == '') {
      errors.cancel = {
        message: 'Campo obrigatório',
      };
    } else if (
      canCancel == 'Y' &&
      ((withRefund == 'Y' && withDueDate == '') ||
        (withRefund == 'N' && withAditionalCost == ''))
    ) {
      errors.cancel = {
        message: 'Campo obrigatório',
      };
    } else {
      if (canCancel == 'Y') {
        if (
          (withAditionalCost == 'Y' && !cancelCostPercentage) ||
          (withRefund == 'Y' &&
            withDueDate == 'Y' &&
            (!cancelRefundPercentage ||
              !cancelLimitDay ||
              !cancelRefundPercentageAfter)) ||
          (withRefund == 'Y' && withDueDate == 'N' && !cancelRefundPercentage)
        ) {
          errors.cancel = {
            message: 'Campo obrigatório',
          };
        }
      }
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setLoading(false);
      return;
    }

    let bagagem = `${quantidadeBagagem}-${pesoBagagem}`;
    const updateData = {
      custo: 0,
      ...(contratante !== undefined && contratante !== null && { contratante }),
      ...(dataIda !== undefined && dataIda !== null && { data_frete: dataIda }),
      ...(primeiraclasseNorma !== undefined &&
        primeiraclasseNorma !== null && {
          primeiraclasse_frete: primeiraclasseNorma,
        }),
      ...(premiumNorma !== undefined &&
        premiumNorma !== null && { premium_frete: premiumNorma }),
      ...(executivaNorma !== undefined &&
        executivaNorma !== null && { executiva_frete: executivaNorma }),
      ...(bagagem !== undefined && bagagem !== null && { bagagem }),
      ...(active !== undefined && active !== null && { ativo: active }),
      ...(ida !== undefined && ida !== null && { rota_ida: ida }),
      ...(dataVolta !== undefined &&
        dataVolta !== null && { date_volta: dataVolta }),
      ...(volta !== undefined && volta !== null && { rota_volta: volta }),
    };

    api
      .patch(`/charters/${id}`, updateData)
      .then(async (response) => {
        if (response.data.success) {
          let dataChange;
          if (canChange == 'Y') {
            dataChange = {
              changeWithCost: withCost == 'Y',
              changeCostValue: changeCost,
              changeLimitDays: limitDay,
            };
            await api.patch(`charters/${id}/change-rules`, dataChange);
          } else {
            dataChange = {
              changeWithCost: withCost === 'Y',
              isActive: false,
            };
            await api.delete(`charters/${id}/change-rules`);
          }

          let dataCancel;
          if (canCancel == 'Y') {
            dataCancel = buildCancelRulesData({
              hasRefund: withRefund === 'Y',
              hasDueDate: withDueDate === 'Y',
              withAditionalCost,
              cancelCostPercentage,
              cancelRefundPercentage,
              cancelLimitDay,
              cancelRefundPercentageAfter,
            });
            await api.patch(`charters/${id}/cancel-rules`, dataCancel);
          } else {
            dataCancel = {
              cancelWithRefund: withRefund === 'Y',
              cancelWithCost: withAditionalCost === 'Y',
              refundWithDeadline: withDueDate === 'Y',
            };
            await api.delete(`charters/${id}/cancel-rules`);
          }

          toaster('success', 'Alterado com sucesso!');
          setLoading(false);
          setTimeout(
            () => navigate(PagePath.AereoSaidasPrecificar.replace(':id', id)),
            2000,
          );
        } else {
          toaster('error', response.data.message);
          setLoading(false);
        }
      })
      .catch((error) => console.log(error));
  }

  function alteraRotaIda() {
    setIda('');
  }
  function alteraRotaVolta() {
    setVolta('');
  }

  const today = new Date();

  const quantity = useMemo(
    () =>
      Array.from({ length: 3 }).map((_, index) => {
        return {
          value: index,
          label: index,
        };
      }),
    [],
  );

  const dataDepartureRoutes = dataFilterBaseRotasOrigem.find(
    (data) => data.id_rota === ida,
  );
  const dataReturnRoutes = dataFilterBaseRotasOrigem.find(
    (data) => data.id_rota === volta,
  );

  async function confirmInactivate() {
    setActive((prev) => !prev);
    setModalOpen((prev) => !prev);
  }

  function handleChangeStatus() {
    if (active) {
      return setModalOpen((prev) => !prev);
    }
    if (selectedDepartureDate <= today && !active) {
      return toaster(
        'error',
        'Não é possível ativar uma saída que possua data posterior ao dia atual',
      );
    }
    return setActive((prev) => !prev);
  }

  const resetChangeRulesValues = (e) => {
    setChangeCost();
    setLimitDay();
  };

  const resetCancelRulesValues = (e) => {
    setCancelRefundPercentageAfter();
    setCancelLimitDay();
    setCancelCostPercentage();
    setCancelRefundPercentage();
  };

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          {
            label: 'Aéreo - Saídas',
            link: PagePath.AereoSaidas,
          },
          {
            label: 'Editar saída',
            link: PagePath.AereoSaidasEditar,
          },
        ]}
        title="Editar saída"
      />

      <div>
        <form onSubmit={(e) => validaForm(e)}>
          <div className="flex flex-col gap-4 rounded-md bg-white p-[15px]">
            <div className="flex flex-col gap-4 rounded-md !border border-solid !border-neutral-300 p-4">
              <Tag
                value={
                  <div className="flex items-center gap-1">
                    <IoAirplaneOutline /> {volta ? 'Rota de ida' : 'Trecho'}
                  </div>
                }
              />

              <div className="col colSelect">
                {ida?.toString().trim().length > 0 ? (
                  <>
                    <label className="m-0 mb-2 text-sm font-medium text-neutral-800">
                      Rota
                    </label>
                    <RouteCard
                      data={dataDepartureRoutes}
                      onClick={alteraRotaIda}
                    />
                  </>
                ) : (
                  <DropdownListRoutes
                    placeholder="Digite a IATA, Cidade ou Estado"
                    dataSearch={dataBaseRotasOrigem}
                    value={departureOrigin}
                    defaultValue={ida}
                    label="Rota"
                    onChange={(value) => setDepartureOrigin(value)}
                    onSelectRoute={(route) => setIda(route.id_rota)}
                  />
                )}
              </div>

              <div className="col-3 w-48">
                <InputDate
                  label="Data de ida"
                  value={dataIda}
                  onChange={(event) => setDataIda(event.target.value)}
                  required
                />
              </div>
            </div>

            {isOnlyOneExcerpt && (
              <div className="flex flex-col gap-4 rounded-md !border border-solid !border-neutral-300 p-4">
                <Tag
                  value={
                    <div className="flex items-center gap-1">
                      <IoAirplaneOutline className="flip" /> Rota de volta
                    </div>
                  }
                />

                <div className="col colSelect">
                  {volta?.toString().trim().length > 0 ? (
                    <>
                      <label className="m-0 mb-2 text-sm font-medium text-neutral-800">
                        Rota
                      </label>
                      <RouteCard
                        data={dataReturnRoutes}
                        onClick={alteraRotaVolta}
                      />
                    </>
                  ) : (
                    <DropdownListRoutes
                      placeholder="Digite a IATA, Cidade ou Estado"
                      dataSearch={dataBaseRotasOrigem}
                      value={returnOrigin}
                      defaultValue={volta}
                      label="Rota"
                      onChange={(value) => setReturnOrigin(value)}
                      onSelectRoute={(route) => setVolta(route.id_rota)}
                    />
                  )}
                </div>

                <div className="col-3 w-48">
                  <InputDate
                    label="Data de volta"
                    type="date"
                    value={dataVolta}
                    onChange={(event) => setDataVolta(event.target.value)}
                    required
                  />
                </div>
              </div>
            )}

            <div className="flex flex-col gap-5 rounded-md !border border-solid !border-neutral-300 p-4">
              <h4 className="!text-heading-3 !text-primary">
                Bagagem para despachar
              </h4>
              <div className="flex flex-col items-center gap-4 md:flex-row">
                <Select
                  label="Quantidade"
                  value={quantidadeBagagem}
                  options={quantity}
                  onChange={(e) => setQuantidadeBagagem(e)}
                />
                <div className="col-md-6 col-12">
                  <InputText
                    label="Peso em kg"
                    value={pesoBagagem}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[0-9,{1}]*$/.test(value)) {
                        setPesoBagagem(value);
                      }
                    }}
                    placeholder="Insira o peso da bagagem em kg"
                  />
                </div>
              </div>
            </div>

            <div className="flex w-full flex-col gap-5 rounded-md !border  !border-solid !border-neutral-300 p-[15px]">
              <span className="text-base font-semibold text-primary">
                Regras de alteração
              </span>
              <div className="flex w-full gap-4">
                <label className="m-0">Poderá alterar?</label>
                <div className="flex gap-3">
                  <InputRadio
                    label="Sim"
                    checked={canChange == 'Y'}
                    onChange={() => setCanChange('Y')}
                  />
                  <InputRadio
                    label="Não"
                    checked={canChange == 'N'}
                    onChange={() => {
                      setCanChange('N');
                      setWithCost('');
                      resetChangeRulesValues();
                    }}
                  />
                </div>
              </div>
              {canChange == 'Y' && (
                <div className="flex w-full gap-4">
                  <label className="m-0">Com custo adicional?</label>
                  <div className="flex gap-3">
                    <InputRadio
                      label="Sim"
                      checked={withCost == 'Y'}
                      onChange={() => {
                        setWithCost('Y');
                        resetChangeRulesValues();
                      }}
                    />
                    <InputRadio
                      label="Não"
                      checked={withCost == 'N'}
                      onChange={() => {
                        setWithCost('N');
                        resetChangeRulesValues();
                      }}
                    />
                  </div>
                </div>
              )}
              {canChange == 'Y' && withCost == 'Y' && (
                <div className="flex w-full gap-4">
                  <div className="w-[70px]">
                    <label>Custo de</label>
                  </div>
                  <div className="w-[120px]">
                    <IntlCurrencyInput
                      currency="BRL"
                      config={currencyConfig}
                      placeholder="Valor em R$"
                      className="currency mt-1 text-sm"
                      value={changeCost}
                      error={!changeCost && errors?.change?.message}
                      onChange={handleChangeCost}
                    />
                  </div>
                </div>
              )}
              {canChange == 'Y' && withCost == 'N' && (
                <div className="flex w-full gap-4">
                  <label>Até</label>
                  <div className="w-[140px]">
                    <InputText
                      placeholder="Quantos dias?"
                      className="mt-1"
                      error={!limitDay && errors?.change?.message}
                      isErrorMessage={false}
                      value={limitDay}
                      onChange={handleLimitDay}
                    />
                  </div>
                  <div className="w-[360px]">
                    <label>
                      dias antes da data de partida. Antes com custo de
                    </label>
                  </div>
                  <div className="w-[150px]">
                    <IntlCurrencyInput
                      currency="BRL"
                      config={currencyConfig}
                      placeholder="Valor em R$"
                      className="currency mt-1 text-sm"
                      value={changeCost}
                      error={!changeCost && errors?.change?.message}
                      onChange={handleChangeCost}
                    />
                  </div>
                </div>
              )}
              {errors?.change && (
                <div className="flex w-full gap-4">
                  <Form.Message error={errors?.change} />
                </div>
              )}
            </div>
            <div className="flex w-full flex-col gap-5 rounded-md !border  !border-solid !border-neutral-300 p-[15px]">
              <span className="text-base font-semibold text-primary">
                Regras de cancelamento
              </span>
              <div className="flex w-full gap-4">
                <label className="m-0">Poderá cancelar?</label>
                <div className="flex gap-3">
                  <InputRadio
                    label="Sim"
                    checked={canCancel == 'Y'}
                    onChange={() => setCanCancel('Y')}
                  />
                  <InputRadio
                    label="Não"
                    checked={canCancel == 'N'}
                    onChange={() => {
                      setCanCancel('N');
                      setWithRefund('');
                      setWithAditionalCost('');
                      setWithDueDate('');
                      resetCancelRulesValues();
                    }}
                  />
                </div>
              </div>
              {canCancel == 'Y' && (
                <div className="flex w-full gap-4">
                  <label className="m-0">Com reembolso?</label>
                  <div className="flex gap-3">
                    <InputRadio
                      label="Sim"
                      checked={withRefund == 'Y'}
                      onChange={() => setWithRefund('Y')}
                    />
                    <InputRadio
                      label="Não"
                      checked={withRefund == 'N'}
                      onChange={() => {
                        setWithRefund('N');
                        setWithAditionalCost('');
                        setWithDueDate('');
                        resetCancelRulesValues();
                      }}
                    />
                  </div>
                </div>
              )}
              {canCancel == 'Y' && withRefund == 'N' && (
                <div className="flex w-full gap-4">
                  <label className="mt-0">Com custo adicional?</label>
                  <div className="w-[70px]">
                    <InputRadio
                      label="Sim"
                      checked={withAditionalCost == 'Y'}
                      onChange={() => setWithAditionalCost('Y')}
                    />
                  </div>
                  <div className="w-[129px]">
                    <InputRadio
                      label="Não"
                      checked={withAditionalCost == 'N'}
                      onChange={() => {
                        setWithAditionalCost('N');
                        resetCancelRulesValues();
                      }}
                    />
                  </div>
                </div>
              )}
              {canCancel == 'Y' &&
                withRefund == 'N' &&
                withAditionalCost == 'Y' && (
                  <div className="flex w-full gap-4">
                    <label>Com custo adicional de</label>
                    <div className="w-[90px]">
                      <InputText
                        placeholder="Ex: 10%"
                        className="mt-1"
                        error={!cancelCostPercentage && errors?.cancel?.message}
                        isErrorMessage={false}
                        value={cancelCostPercentage}
                        onChange={(event) => {
                          const value = inputFormatterPercentage({
                            value: event.target.value,
                            isDelete:
                              event.nativeEvent.inputType ===
                              'deleteContentBackward',
                          });
                          setCancelCostPercentage(value);
                        }}
                      />
                    </div>
                    <div className="w-[150px]">
                      <label>do valor da tarifa.</label>
                    </div>
                  </div>
                )}

              {canCancel == 'Y' && withRefund == 'Y' && (
                <div className="flex w-full gap-4">
                  <label className="mt-0">Com data limite?</label>
                  <div className="flex gap-3">
                    <InputRadio
                      label="Sim"
                      checked={withDueDate == 'Y'}
                      onChange={() => setWithDueDate('Y')}
                    />
                    <InputRadio
                      label="Não"
                      checked={withDueDate == 'N'}
                      onChange={() => {
                        setWithDueDate('N');
                        resetCancelRulesValues();
                      }}
                    />
                  </div>
                </div>
              )}

              {canCancel == 'Y' && withRefund == 'Y' && withDueDate == 'N' && (
                <div className="flex w-full gap-4">
                  <label>Reembolso de</label>
                  <div className="w-[90px]">
                    <InputText
                      placeholder="Ex: 10%"
                      className="mt-1"
                      error={!cancelRefundPercentage && errors?.cancel?.message}
                      isErrorMessage={false}
                      value={cancelRefundPercentage}
                      onChange={(event) => {
                        const value = inputFormatterPercentage({
                          value: event.target.value,
                          isDelete:
                            event.nativeEvent.inputType ===
                            'deleteContentBackward',
                        });
                        setCancelRefundPercentage(value);
                      }}
                    />
                  </div>
                  <div className="w-[150px]">
                    <label>do valor da tarifa.</label>
                  </div>
                </div>
              )}

              {canCancel == 'Y' && withRefund == 'Y' && withDueDate == 'Y' && (
                <>
                  <div className="flex w-full gap-4">
                    <label>Reembolso de</label>
                    <div className="w-[90px]">
                      <InputText
                        placeholder="Ex: 10%"
                        className="mt-1"
                        error={
                          !cancelRefundPercentage && errors?.cancel?.message
                        }
                        isErrorMessage={false}
                        value={cancelRefundPercentage}
                        onChange={(event) => {
                          const value = inputFormatterPercentage({
                            value: event.target.value,
                            isDelete:
                              event.nativeEvent.inputType ===
                              'deleteContentBackward',
                          });
                          setCancelRefundPercentage(value);
                        }}
                      />
                    </div>
                    <div className="w-[150px]">
                      <label>do valor da tarifa até </label>
                    </div>
                    <div className="w-[140px]">
                      <InputText
                        placeholder="Quantos dias?"
                        className="mt-1"
                        error={!cancelLimitDay && errors?.cancel?.message}
                        isErrorMessage={false}
                        value={cancelLimitDay}
                        onChange={handleCancelLimitDay}
                      />
                    </div>
                    <div className="w-[230px]">
                      <label>dias antes da data de partida. </label>
                    </div>
                  </div>
                  <div className="flex w-full gap-4">
                    <div className="w-[250px]">
                      <label>Após, será reembolsado somente</label>
                    </div>
                    <div className="w-[90px]">
                      <InputText
                        placeholder="Ex: 10%"
                        className="mt-1"
                        value={cancelRefundPercentageAfter}
                        error={
                          !cancelRefundPercentageAfter &&
                          errors?.cancel?.message
                        }
                        isErrorMessage={false}
                        onChange={(event) => {
                          const value = inputFormatterPercentage({
                            value: event.target.value,
                            isDelete:
                              event.nativeEvent.inputType ===
                              'deleteContentBackward',
                          });
                          setCancelRefundPercentageAfter(value);
                        }}
                      />
                    </div>
                    <div className="w-[150px]">
                      <label>da tarifa. </label>
                    </div>
                  </div>
                </>
              )}
              {errors?.cancel && (
                <div className="flex w-full gap-4">
                  <Form.Message error={errors?.cancel} />
                </div>
              )}
            </div>

            <Switch
              label="Ativa"
              checked={active}
              onChange={handleChangeStatus}
            />
            <div className="flex w-full justify-end">
              <Button
                label="Salvar"
                className="w-max px-[75px]"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
      <Modal open={modalOpen}>
        <div className="flex flex-col items-center justify-center px-[190px] py-[60px]">
          <GoAlertFill className="text-warning-500" size={38} />
          <span className="mt-3 text-center text-heading-1 text-warning-500">
            Tem certeza que deseja inativar essa saída?
          </span>
          <p className="mb-[30px] mt-[15px] text-center !text-heading-4 text-neutral-800">
            Ao inativar essa saída, ela será ocultada da listagem de
            disponibilidades de reservas.
          </p>
          <div className="mt-3 flex items-center justify-center gap-[30px]">
            <Button
              variant="ghost"
              label="Cancelar"
              onClick={() => setModalOpen((prev) => !prev)}
            />
            <Button label="Inativar saída" onClick={confirmInactivate} />
          </div>
        </div>
      </Modal>
    </>
  );
}
